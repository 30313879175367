<template>
	<b-overlay :show="loading" rounded="sm">
		<b-card no-body>
			<b-form @submit.prevent="saveData">
				<template v-if="!readOnly">
					<Header />
					<hr class="m-0" />
				</template>
				<!-- ID Info Section -->
				<b-card-body class="mb-5">
					<h4 class="card-title mb-1 py-2 mb-4 border-bottom">ID Info</h4>
					<div class="row">
						<div class="col-6">
							<label class="fw-medium mb-1" for="fname4">Id 1 Type</label>
							<div class="row">
								<div class="col-4">
									<b-form-select required :options="personIdentificationTypes"
										text-field="description" value-field="id"
										v-model="dataSelected.identificationTypeId1"
										:readOnly="readOnly"></b-form-select>
								</div>
								<div class="col-8">
									<b-form-input required :readOnly="readOnly" type="text" placeholder="Id Here"
										v-model="dataSelected.identificationType1"></b-form-input>
								</div>
							</div>
						</div>
						<div class="col-6">
							<label class="fw-medium mb-1" for="fname4">Id 2 Type</label>
							<div class="row">
								<div class="col-4">
									<b-form-select required :options="personIdentificationTypes"
										text-field="description" value-field="id"
										v-model="dataSelected.identificationTypeId2"
										:readOnly="readOnly"></b-form-select>
								</div>
								<div class="col-8">
									<b-form-input required :readOnly="readOnly" type="text" placeholder="Id Here"
										v-model="dataSelected.identificationType2"></b-form-input>
								</div>
							</div>
						</div>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Personalia Section -->

				<b-card-body class="bg-light mb-5">
					<h4 class="card-title mb-1 py-2 mb-4 border-bottom">Personalia</h4>
					<div class="row mb-4">
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Place of Birth</label>
							<b-form-select required :readOnly="readOnly" :options="cities" text-field="name"
								value-field="id" v-model="dataSelected.placeOfBirthId"></b-form-select>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Nationality</label>
							<b-form-select required :readOnly="readOnly" :options="countries" text-field="name"
								value-field="id" v-model="dataSelected.nationalityId"></b-form-select>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Date of birth</label>
							<b-form-datepicker required id="example-datepicker"
								:date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
								class="mb-2" v-model="dataSelected.birthDate" :readOnly="readOnly"></b-form-datepicker>
						</div>
					</div>

					<div class="row">
						<div class="col-4">
							<label class="fw-medium mb-1" for="comp4">Gender</label>
							<b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
								<b-form-radio-group required :options="[
									{ text: 'Male', value: 'M' },
									{ text: 'Female', value: 'F' },
								]" text-field="text" value-field="value" :aria-describedby="ariaDescribedby" name="input-gender"
									v-model="dataSelected.gender" :readOnly="readOnly"></b-form-radio-group>
							</b-form-group>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1" for="comp4">Marital Status</label>
							<b-form-group class="mb-0">
								<b-form-select :readOnly="readOnly" required :options="maritalStatus" text-field="name"
									value-field="id" v-model="dataSelected.maritalStatusId"></b-form-select>
							</b-form-group>
						</div>
						<div class="col-4" v-if="dataSelected.maritalStatusId == 1">
							<label class="fw-medium mb-1" for="fname4">Spouses Full Name</label>
							<b-form-input :readOnly="readOnly" required type="text" placeholder="Spouses full name here"
								v-model="dataSelected.spousesFullName"></b-form-input>
						</div>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Bank Info -->
				<b-card-body>
					<h4 class="card-title mb-1 py-2 mb-4 border-bottom">Bank Info</h4>

					<div class="row mb-4">
						<div class="col-6">
							<label class="fw-medium mb-1" for="comp4">Bank</label>
							<b-form-select required :options="banks.filter((e) => e.id != 0)" text-field="name"
								value-field="id" v-model="dataSelected.bankId" :readOnly="readOnly"></b-form-select>
						</div>
						<template v-if="dataSelected.bankId != -1">
							<div class="col-6">
								<label class="fw-medium mb-1" for="comp4">Bank Account Number</label>
								<b-form-input :readOnly="readOnly" required type="text" placeholder="Id Here"
									v-model="dataSelected.bankAccountNumber"></b-form-input>
							</div>
						</template>
					</div>

					<div class="row">
						<div class="col-4">
							<label class="fw-medium mb-1" for="comp4">Existing Bank Debt</label>
							<b-form-select :readOnly="readOnly" required :options="banks.filter((e) => e.id != -1)"
								text-field="name" value-field="id"
								v-model="dataSelected.existingBankDebt"></b-form-select>
						</div>
						<div class="col-4" v-if="dataSelected.existingBankDebt == 9">
							<label class="fw-medium mb-1" for="comp4">Other Institution Name</label>
							<b-form-input :readOnly="readOnly" required type="text" placeholder="Other Institution Name"
								v-model="dataSelected.otherInstitutionName"></b-form-input>
						</div>
						<div class="col-4" v-if="dataSelected.existingBankDebt != 0">
							<label class="fw-medium mb-1" for="comp4">Existing Debt Amount</label>
							<b-form-input :readOnly="readOnly" required type="number" placeholder="Id Here"
								v-model="dataSelected.existingDebtAmount"></b-form-input>
						</div>
					</div>
				</b-card-body>
				<!-- End Section -->


				<!-- Co Applicant Section -->

				<b-card-body class="bg-light mb-5">
					<h4 class="card-title mb-1 py-2 mb-4 border-bottom">Co Applicant</h4>
					<div class="row mb-4">
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Co Applicant FirstName</label>
							<b-form-input :readOnly="readOnly" required type="text" placeholder="FirstName Here"
								v-model="dataSelected.coApplicantFirstName"></b-form-input>
						</div>

						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Co Applicant LastName</label>
							<b-form-input :readOnly="readOnly" required type="text" placeholder="LastName Here"
								v-model="dataSelected.coApplicantLastName"></b-form-input>
						</div>

						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Co Applicant Phone</label>
							<b-form-input :readOnly="readOnly" required type="text" placeholder="Phone Here"
								v-model="dataSelected.coApplicantPhone1"></b-form-input>
						</div>

					</div>

				</b-card-body>
				<!-- End Section -->

				<!-- Actions Section -->
				<div class="p-35" v-if="!readOnly">
					<div class="btn-grp text-right">
						<b-button pill :disabled="loadingSave" variant="primary" class="px-4" type="submit">
							<b-spinner small v-if="loadingSave"></b-spinner>
							<span v-else>Save</span>
						</b-button>
					</div>
				</div>
				<!-- End Section -->
			</b-form>
		</b-card>
	</b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header.vue";
export default {
	name: "Customer",
	components: {
		Header,
	},
	props: {
		new: Boolean,
		readOnly: Boolean,
	},
	data: () => ({
		optionsCountry: [
			{ value: null, text: "Please select an option" },
			{ value: "Aruba", text: "Aruba" },
			{ value: "US", text: "Estados Unidos" },
		],
		optionsTitle: [
			{ value: null, text: "Please select an option" },
			{ value: "mrs", text: "Mrs" },
		],
		countrySelected: null,
		titleSelected: null,
		dataSelected: {
			identificationTypeId1: null,
			identificationType1: "",
			identificationTypeId2: null,
			identificationType2: "",
			placeOfBirthId: null,
			nationalityId: null,
			birthDate: null,
			gender: null,
			maritalStatusId: null,
			spousesFullName: null,
			bankId: null,
			bankAccountNumber: "",
			existingBankDebt: null,
			existingDebtAmount: null,
			otherInstitutionName: null,
			coApplicantFirstName: null,
			coApplicantLastName: null,
			coApplicantPhone1: null
		},
		loadingSave: false,

		maritalStatus: [
			{
				id: 1,
				name: "Casa",
			},
			{
				id: 2,
				name: "Soltero/-a",
			},
			{
				id: 3,
				name: "Biba Hunto",
			},
			{
				id: 4,
				name: "Otro",
			},
		],

		//loaders
		loading: false,
		loadingDocumentTypes: false,
	}),
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
			documentTypes: "documentsTypes/getDocumentsTypes",
			countries: "countries/getCountries",
			cities: "cities/getCities",
			banks: "banks/getBanksDDL",
			personIdentificationTypes: "personIdentificationTypes/getPersonIdentificationTypes",
		}),
	},
	methods: {
		async getData() {
			await this.$store.dispatch("personIdentificationTypes/get_person_identification_types");
			await this.$store.dispatch("countries/get_allCountries", { skip: 1, take: 9999 });
			await this.$store.dispatch("cities/get_allCities", { skip: 1, take: 9999 });
			await this.$store.dispatch("banks/get_allBanksDDL");
		},
		async getAdditionalDetails() {
			this.loading = true;
			let id = null
			if (this.$route.path.includes('/customers/detail')) {
				id = this.$route.params.customer;
			} else {
				id = this.$route.query.customerId
			}
			await this.getData();
			if (this.customer.additionalDetails.id != 0) {
				this.dataSelected = await this.$store.dispatch("additionalDetails/get_additionalDetailById", id);
			}
			this.loading = false;
		},
		async saveData() {
			const id = this.$route.params.customer;


			if (this.dataSelected.identificationType1 == 'NOT DEFINED' || this.dataSelected.identificationTypeId1 == 1) {
				this.$bvToast.toast("The ID type is required", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
				return
			}


			this.dataSelected.customerId = this.$route.query.customerId;
			this.loadingSave = true;


			if (this.customer.additionalDetails.id == 0) {
				await this.$store.dispatch("additionalDetails/add_additionalDetail", { id, data: this.dataSelected });
			} else {
				await this.$store.dispatch("additionalDetails/update_additionalDetail", { id, data: this.dataSelected });
			}

			await this.$store.dispatch("productRequest/get_processResquest", this.$route.params.customer);

			this.loadingSave = false;
		},
	},
	created() {
		if (this.new) {
			this.getData();
		} else {
			this.getAdditionalDetails();
		}
	},
};
</script>
