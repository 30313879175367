<template>
	<div>
		<b-card no-body>
			<template v-if="!readOnly">
				<Header />
				<hr class="m-0" />
			</template>
			<div>
				<!-- Search Control -->
				<b-card-body class="py-3 px-0 d-flex justify-content-between">
					<b-form-group class="mb-2 w-25">
						<b-input-group>
							<b-form-input id="filter-input" v-model="filter" type="search"
								placeholder="Type to Search"></b-form-input>

							<b-input-group-append>
								<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div class="d-flex" v-if="!readOnly">
						<!-- <b-button variant="light-info" class="mr-2">
                                Active Employments <b-badge variant="light">1</b-badge>
                            </b-button> -->
						<b-button v-b-modal.modal-register variant="primary">Add</b-button>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Data Table -->
				<b-table style="min-height: 250px;" show-empty responsive small class="mb-0" head-variant="light"
					:items="employments" :filter="filter" :fields="fields" hover>
					<template #cell(id)="data"> #{{ data.item.id }} </template>
					<template #cell(fromDate)="data"> {{ formatDate(data.item.fromDate) }} </template>
					<template #cell(toDate)="data"> {{ formatDate(data.item.toDate) }} </template>
					<template #cell(isActive)="data">
						<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
						<b-badge variant="danger" v-else>Inactive</b-badge>
					</template>

					<template #cell(isVerified)="data">
						<b-badge variant="success" v-if="data.item.isVerified">Verified</b-badge>
						<b-badge variant="danger" v-else>Not Verified</b-badge>
					</template>

					<template #cell(action)="data">
						<b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
							<b-dropdown-item v-b-modal.modal-user @click="editEmployment(data.item)">Edit
								Employment</b-dropdown-item>
							<b-dropdown-item v-b-modal.modal-user-details @click="viewEmployment(data.item)">View
								Employment</b-dropdown-item>
							<b-dropdown-item @click="updateStatus(false, data.item)" v-if="data.item.isActive">Disable
								Status</b-dropdown-item>
							<b-dropdown-item @click="updateStatus(true, data.item)" v-else>Enable
								Status</b-dropdown-item>
							<b-dropdown-item @click="updateVerified(false, data.item)"
								v-if="data.item.isVerified">Unverify</b-dropdown-item>
							<b-dropdown-item @click="updateVerified(true, data.item)" v-else>Verify</b-dropdown-item>
						</b-dropdown>
					</template>
				</b-table>
				<!-- End Section -->

				<!-- Pagination -->
				<div class="p-3 d-flex align-items-center">
					<b-form-group label="Per page" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
						label-cols-lg="9" label-size="sm" label-class="fw-medium" class="mb-0">
						<b-form-select @input="getEmployments" id="per-page-select" v-model="perPage"
							:options="pageOptions" size="sm"></b-form-select>
					</b-form-group>
					<div class="ml-auto">
						<b-pagination @input="getEmployments" v-model="currentPage" :total-rows="totalRows"
							:per-page="perPage" align="fill" class="my-0"></b-pagination>
					</div>
				</div>
				<!-- End Pagination -->
			</div>
		</b-card>

		<!-- ---------------------------------------- -->
		<!-- register modal -->
		<!-- ---------------------------------------- -->
		<b-modal id="modal-register" title="Register Employment" header-bg-variant="light" hide-footer centered
			@close="clearValues">
			<b-form @submit.prevent="addEmployer">
				<b-card-text>
					<b-form-group label="Employer Name" label-for="employer-name" label-class="fw-medium" class="mb-2">
						<b-form-input id="employer-name" type="text" size="sm" placeholder="Name" v-model="name"
							required></b-form-input>
					</b-form-group>
					<b-form-group label="Address" label-for="employer-address" label-class="fw-medium" class="mb-2">
						<b-form-input id="employer-address" type="text" size="sm" placeholder="Address"
							v-model="address" required></b-form-input>
					</b-form-group>
					<b-form-group label="Telephone Number" label-for="employer-telephone-number" label-size="sm"
						label-class="fw-medium" class="mb-2">
						<b-form-input id="employer-telephone-number" type="text" size="sm"
							placeholder="Telephone Number" v-model="phone" required></b-form-input>
					</b-form-group>
					<b-form-group label="Job Description" label-for="job-description" label-class="fw-medium"
						class="mb-2">
						<b-form-input id="job-description" type="text" size="sm" placeholder="Job Description"
							v-model="jobDescription" required></b-form-input>
					</b-form-group>
					<b-form-group label="From Date" label-for="from-date" label-class="fw-medium" class="mb-2">
						<b-form-input id="from-date" type="date" size="sm" placeholder="From Date" v-model="date"
							required></b-form-input>
					</b-form-group>
					<b-form-group label="To Date" label-for="to-date" label-class="fw-medium" class="mb-2">
						<b-form-input id="to-date" type="date" size="sm" placeholder="To Date" v-model="toDate"
							required></b-form-input>
					</b-form-group>
					<b-form-group label="Monthly Salary" label-for="monthly-salary" label-class="fw-medium"
						class="mb-2">
						<b-form-input id="monthly-salary" type="number" size="sm" placeholder="Monthly Salary"
							v-model="salary" required min="1"></b-form-input>
					</b-form-group>

					<b-button block :disabled="loadingAddEmployment" variant="primary" type="submit">
						<b-spinner small v-if="loadingAddEmployment"></b-spinner>
						<span v-else>Save Employment</span>
					</b-button>
				</b-card-text>
			</b-form>
		</b-modal>
		<!-- ---------------------------------------- -->
		<!-- end register modal -->
		<!-- ---------------------------------------- -->

		<!-- ---------------------------------------- -->
		<!-- confirmation modal -->
		<!-- ---------------------------------------- -->
		<b-modal id="modal-confirmation" title="Confirmation" header-bg-variant="light" hide-footer size="sm" centered>
			<b-card-text>
				<div class="text-center mb-3 mt-3">
					<feather type="trash" class="feather-lg text-danger"></feather>
					<h5 class="mb-0 mt-3">Changes Saved !</h5>
					<h6 class="text-muted mt-2 font-weight-normal">
						Remove Employment
					</h6>
				</div>

				<b-button block variant="danger" class="mt-5" @click="Delete">Delete</b-button>
				<b-button block variant="outline-secondary" class="mt-3 border-0"
					@click="$bvModal.hide('modal-confirmation')">No, Thanks.</b-button>
			</b-card-text>
		</b-modal>
		<!-- ---------------------------------------- -->
		<!-- end confirmation modal -->
		<!-- ---------------------------------------- -->

		<!-- Modal View -->
		<b-modal id="modal-view" title="View Employment" hide-footer centered>
			<b-card-text>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Employer Name</h6>
					<span class="mail-desc d-block text-muted">{{ employmentSelected.employerName }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Employer Address</h6>
					<span class="mail-desc d-block text-muted">{{ employmentSelected.employerAddress }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Employer Telephone Number</h6>
					<span class="mail-desc d-block text-muted">{{ employmentSelected.employerTelephoneNumber }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Job Description</h6>
					<span class="mail-desc d-block text-muted">{{ employmentSelected.jobDescription }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">From Date</h6>
					<span class="mail-desc d-block text-muted">{{ formatDate(employmentSelected.fromDate) }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">To Date</h6>
					<span class="mail-desc d-block text-muted">{{ formatDate(employmentSelected.toDate) }}</span>
				</div>
				<div class="border-bottom d-flex justify-content-between py-2">
					<h6 class="message-title text-primary mb-0 mt-1">Monthly Salary</h6>
					<span class="mail-desc d-block text-muted">$ {{ employmentSelected.monthlySalary }}</span>
				</div>
				<div class="d-flex justify-content-end mt-4">
					<b-button variant="secondary" @click="$bvModal.hide('modal-view')">Cancel</b-button>
				</div>
			</b-card-text>
		</b-modal>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "./Header.vue";
export default {
	name: "Customer",
	components: {
		Header,
	},
	props: {
		new: Boolean,
		readOnly: Boolean,
	},
	data: () => ({
		page: {
			title: "employment",
		},
		filter: null,
		fields: [
			{
				key: "id",
				sortable: true,
			},
			{
				key: "employerName",
				label: "Employer Name",
				sortable: true,
			},
			{
				key: "jobDescription",
				label: "Job Description",
				sortable: true,
			},
			{
				key: "fromDate",
				label: "From",
				sortable: true,
			},
			{
				key: "toDate",
				label: "To",
				sortable: true,
			},
			{
				key: "monthlySalary",
				label: "Monthly Salary",
				sortable: false,
			},
			{
				key: "isActive",
				label: "Active",
				sortable: true,
			},
			{
				key: "isVerified",
				label: "Verified",
				sortable: true,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],

		name: "",
		address: "",
		phone: "",
		jobDescription: "",
		date: "",
		toDate: "",
		salary: "",
		loading: false,
		loadingAddEmployment: false,

		employmentSelected: {},
		isEditing: false,
	}),
	methods: {
		Delete() {
			this.items = this.items.filter((item) => item.id !== "123");
		},
		clearValues() {
			this.name = "";
			this.address = "";
			this.phone = "";
			this.jobDescription = "";
			this.date = "";
			this.toDate = "";
			this.salary = "";
		},
		formatDate(date) {
			if (!date) return;
			return date.split("T")[0];
		},
		getEmployments() {
			this.loading = true;
			let data = {
				skip: this.currentPage,
				take: this.perPage,
				customerId: this.$route.path.includes('/customers/detail') ? this.$route.params.customer : this.$route.query.customerId,
			};
			this.$store.dispatch("employments/get_employments", data);
			this.loading = false;
		},
		async addEmployer() {
			this.loadingAddEmployment = true;
			let data = {
				customerId: this.customer.additionalDetails.customerId,
				loanApplicationId: this.$route.params.customer,
				employerName: this.name,
				employerAddress: this.address,
				employerTelephoneNumber: this.phone,
				jobDescription: this.jobDescription,
				fromDate: this.date,
				toDate: this.toDate,
				monthlySalary: this.salary,
			};

			if (this.isEditing) {
				data.id = this.employmentSelected.id;
				data.isActive = true;
				data.isVerified = true;
				data.modifiedBy = 1;
				data.clasificationId = 1;
				await this.$store.dispatch("employments/update_employment", data);
				this.isEditing = false;
			} else {
				await this.$store.dispatch("employments/add_employment", data);
			}

			//clear data
			this.clearValues();

			await this.getEmployments();
			this.$bvModal.hide("modal-register");
			this.loadingAddEmployment = false;
		},
		async viewEmployment(item) {
			this.employmentSelected = await this.$store.dispatch("employments/get_employmentById", item.id);
			this.$bvModal.show("modal-view");
		},
		async editEmployment(item) {
			this.isEditing = true;
			this.employmentSelected = await this.$store.dispatch("employments/get_employmentById", item.id);

			this.name = this.employmentSelected.employerName;
			this.address = this.employmentSelected.employerAddress;
			this.phone = this.employmentSelected.employerTelephoneNumber;
			this.jobDescription = this.employmentSelected.jobDescription;
			this.toDate = this.employmentSelected.toDate.split("T")[0];
			this.fromDate = this.employmentSelected.fromDate.split("T")[0];
			this.salary = this.employmentSelected.monthlySalary;

			this.$bvModal.show("modal-register");
		},
		async updateStatus(status, item) {
			let data = {
				id: item.id,
				employerName: item.employerName,
				employerAddress: item.employerAddress,
				employerTelephoneNumber: item.employerTelephoneNumber,
				jobDescription: item.jobDescription,
				isActive: status,
				modifiedBy: 1,
			};
			await this.$store.dispatch("employments/update_employmentStatus", data);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.params.customer);
			await this.getEmployments();
		},
		async updateVerified(status, item) {
			let data = {
				id: item.id,
				employerName: item.employerName,
				employerAddress: item.employerAddress,
				employerTelephoneNumber: item.employerTelephoneNumber,
				jobDescription: item.jobDescription,
				isVerified: status,
				modifiedBy: 1,
				loanApplicationId: this.$route.params.customer,
			};
			await this.$store.dispatch("employments/update_employmentVerification", data);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.params.customer);
			await this.getEmployments();
		},
	},
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
			employments: "employments/getEmployments",
		}),
	},
	mounted() {
		if (!this.new) {
			this.getEmployments();
		}

		if (!this.readOnly) {
			this.fields.push({
				key: "action",
				label: "Actions",
				sortable: false,
			});
		}
	},
};
</script>
