<template>
	<div>
		<!-- 1 -->
		<b-card no-body class="mb-4">
			<template v-if="!readOnly">
				<Header />
				<hr class="m-0" />
			</template>
			<!-- Search Control -->
			<div class="mb-2 d-flex justify-content-between">
				<b-form-group class="mb-2 w-25">
					<b-input-group>
						<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

						<b-input-group-append>
							<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<div class="d-flex align-items-center" v-if="currentRoute.includes('/loans/applications') && !readOnly">
					<b-button variant="primary" class="mr-2" v-b-modal.modal-comment>Add</b-button>
				</div>
			</div>
			<!-- End Section -->

			<!-- Data Table -->
			<b-table
				style="min-height: 250px;"
				show-empty
				responsive
				small
				class="mb-0"
				head-variant="light"
				:items="comments"
				:filter="filter"
				:fields="fields"
				hover
			>
				<template #cell(id)="data"> #{{ data.item.id }} </template>
				<template #cell(createDate)="data"> {{ data.item.createDate.split("T")[0] }} </template>
				<template #cell(isActive)="data">
					<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
					<b-badge variant="danger" v-else>Inactive</b-badge>
				</template>
				<template #cell(action)="data">
					<b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
						<b-dropdown-item v-if="currentRoute.includes('/loans/applications')" @click="showEditModal(data)">Edit comment</b-dropdown-item>
						<b-dropdown-item
							v-if="data.item.isActive && currentRoute.includes('/loans/applications')"
							v-b-modal.modal-disable
							@click="commentSelected = data.item"
							>Disable comment</b-dropdown-item
						>
						<b-dropdown-item
							v-if="!data.item.isActive && currentRoute.includes('/loans/applications')"
							v-b-modal.modal-enable
							@click="commentSelected = data.item"
							>Enable comment</b-dropdown-item
						>
					</b-dropdown>
				</template>
			</b-table>
			<!-- End Section -->

			<!-- Pagination -->
			<div class="p-3 d-flex align-items-center">
				<b-form-group
					label="Per page"
					label-for="per-page-select"
					label-cols-sm="6"
					label-cols-md="4"
					label-cols-lg="9"
					label-size="sm"
					label-class="fw-medium"
					class="mb-0"
				>
					<b-form-select @input="getComments" id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
				</b-form-group>
				<div class="ml-auto">
					<b-pagination
						@input="getComments"
						v-model="currentPage"
						:total-rows="totalComments"
						:per-page="perPage"
						align="fill"
						class="my-0"
					></b-pagination>
				</div>
			</div>
			<!-- End Section -->
		</b-card>

		<b-modal id="modal-comment" hide-footer centered :title="isEditing ? 'Edit comment' : 'Add a new comment'" @close="clearData">
			<b-form @submit.prevent="addComment">
				<b-form-group label="Comment" label-class="fw-medium mb-1" label-for="input-2">
					<b-form-textarea required id="input-2" placeholder="Your comment here" rows="3" no-resize v-model="comment"></b-form-textarea>
				</b-form-group>
				<b-button block variant="primary" type="submit" :disabled="loadingAddComment">Save Comment</b-button>
			</b-form>
		</b-modal>

		<b-modal id="modal-disable" hide-footer centered title="Disable comment">
			<b-card-text>
				Are you sure to want to disable this comment?
			</b-card-text>
			<div class="d-flex justify-content-end">
				<b-button variant="danger" class="mr-2" @click="updateStatus(false)">Yes</b-button>
				<b-button variant="secondary" @click="$bvModal.hide('modal-disable')">No</b-button>
			</div>
		</b-modal>

		<b-modal id="modal-enable" hide-footer centered title="Enable comment">
			<b-card-text>
				Are you sure to want to enable this comment?
			</b-card-text>
			<div class="d-flex justify-content-end">
				<b-button variant="primary" class="mr-2" @click="updateStatus(true)">Yes</b-button>
				<b-button variant="secondary" @click="$bvModal.hide('modal-enable')">No</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header.vue";
export default {
	name: "Customer",
	components: {
		Header,
	},
	props: {
		new: Boolean,
		readOnly: Boolean,
	},
	data: () => ({
		page: {
			title: "SeachTable",
		},
		filter: null,
		fields: [
			{
				key: "id",
				sortable: true,
			},
			{
				key: "createDate",
				label: "Date",
				sortable: true,
			},
			{
				key: "description",
				label: "Comments",
				sortable: true,
			},
			{
				key: "isActive",
				label: "Status",
				sortable: true,
			},
			{
				key: "createdByUserName",
				label: "Created By",
				sortable: true,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		comment: null,
		loadingAddComment: false,
		commentSelected: null,
		isEditing: false,
		currentRoute: window.location.pathname,
	}),
	methods: {
		onRowClicked(item) {
			this.$router.push(`/customers/${item.id}`);
		},
		getComments() {
			if (this.$route.path.includes("/loans/applications")) {
				this.$store.dispatch("comments/get_commentsByLoanAppId", this.$route.params.customer);
			} else {
				this.$store.dispatch("comments/get_comments", { skip: this.currentPage, take: this.perPage, customerId: this.$route.params.customer });
			}
		},
		clearData() {
			this.comment = "";
			this.commentSelected = null;
			this.isEditing = false;
		},
		async addComment() {
			this.loadingAddComment = true;

			if (this.isEditing) {
				await this.$store.dispatch("comments/update_comment", {
					comment: this.comment,
					id: this.commentSelected.id,
					customerId: this.customer.additionalDetails.customerId,
					loanApplicationId: this.$route.params.customer,
				});
				this.isEditing = false;
			} else {
				await this.$store.dispatch("comments/add_comment", {
					comment: this.comment,
					customerId: this.customer.additionalDetails.customerId,
					loanApplicationId: this.$route.params.customer,
				});
			}

			await this.getComments();
			this.$bvModal.hide("modal-comment");
			this.comment = "";
			this.loadingAddComment = false;
		},
		async updateStatus(status) {
			await this.$store.dispatch("comments/update_status", {
				isActive: status,
				id: this.commentSelected.id,
				description: this.commentSelected.description,
			});
			await this.getComments();
			this.commentSelected = null;
			this.$bvModal.hide("modal-disable");
			this.$bvModal.hide("modal-enable");
		},
		showEditModal(data) {
			this.isEditing = true;
			this.commentSelected = data.item;
			this.comment = data.item.description;
			this.$bvModal.show("modal-comment");
		},
	},
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
			comments: "comments/getComments",
			totalComments: "comments/getTotalComments",
		}),
	},
	mounted() {},
	created() {
		if (!this.new) {
			this.getComments();
		}

		if (!this.readOnly) {
			this.fields.push({
				key: "action",
				label: "Action",
				sortable: false,
			});
		}
	},
};
</script>
